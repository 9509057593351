import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout/layout';
import {
  Headline,
  ExternalLink,
  StyledLink,
} from '../components/layout/typography';
import Signup from '../components/signup';

import formatGCMSDate from '../utils/date';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function PostPage({ data: { graphCmsPost: post } }) {
  let formattedDate = formatGCMSDate(post.date);

  return (
    <Layout
      title={post.seo.title}
      description={post.seo.description}
      keywords={post.seo.keywords}
    >
      <div className="relative py-16 pt-48 bg-white overflow-hidden max-w-6xl mx-auto">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <div className="flex gap-x-1 mx-auto justify-center">
              {post.tags.map((tag) => (
                <div key={tag.name} className="inline-block">
                  <span
                    style={{
                      color: tag.textColor.hex,
                      backgroundColor: tag.backgroundColor.hex,
                    }}
                    className="uppercase inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium"
                  >
                    {tag.name}
                  </span>
                </div>
              ))}
            </div>
            <Headline className="mt-2 block text-4xl text-center leading-8 font-extrabold sm:text-5xl">
              {post.title}
            </Headline>
            <p className="mt-8 text-xl text-gray-900 leading-8">
              {post.excerpt}
            </p>
            <p className="my-6 text-base text-center italic text-gray-500 leading-8">
              Published {formattedDate}
            </p>
          </div>
          <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
            <ReactMarkdown
              components={{
                img: ({ node, ...props }) => (
                  <img className="mx-auto max-h-96" alt={node.alt} {...props} />
                ),
                a: ({ node, ...props }) => (
                  <ExternalLink href={node.href} {...props} />
                ),
              }}
            >
              {post.content.markdown}
            </ReactMarkdown>
          </div>
          <div className="mx-auto max-w-2xl py-4 my-8 flex justify-center items-center">
            <div className="flex items-center space-x-4 lg:space-x-6">
              <img
                className="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                src={post.author.picture.url}
                alt=""
              />
              <div className="font-medium text-lg leading-6 space-y-1">
                <h3>{post.author.name}</h3>
                <p className="text-timothy-blue">{post.author.title}</p>
              </div>
            </div>
          </div>
          {post.funnelItems.length > 0 && (
            <div className="pt-6 pb-36">
              <Headline className="mt-2 block text-4xl text-left leading-8 font-extrabold sm:text-5xl">
                Further Reading
              </Headline>
              <div className="mt-10 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {post.funnelItems.map((item) => (
                  <FunnelItem item={item} post={post} />
                ))}
              </div>
            </div>
          )}
          <Signup />
        </div>
      </div>
    </Layout>
  );
}

function FunnelItem({ item, post }) {
  return (
    <Link
      to={item.url}
      key={item.title}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-shrink-0">
        <GatsbyImage
          className="object-cover h-full"
          image={item.teaserImage.localFile.childImageSharp.gatsbyImageData}
          alt={item.title}
          placeholder="blurred"
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <a href={post.href} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{item.title}</p>
            <p className="mt-3 text-base text-gray-500">{item.description}</p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <p className="text-base font-semibold text-timothy-blue hover:text-blue-500">
            <StyledLink>Take me there! &rarr;</StyledLink>
          </p>
        </div>
      </div>
    </Link>
  );
}

export const pageQuery = graphql`
  query PostPageQuery($id: String!) {
    graphCmsPost(id: { eq: $id }) {
      author {
        name
        picture {
          url
        }
        title
      }
      content {
        markdown
      }
      date
      excerpt
      funnelItems {
        title
        description
        url
        teaserImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        keywords
        title
        description
      }
      slug
      tags {
        ... on GraphCMS_Tag {
          id
          name
          textColor {
            hex
          }
          backgroundColor {
            hex
          }
        }
      }
      title
    }
  }
`;
